(function () {
  'use strict';

  angular
    .module('ss.client.modals')
    .component('cancelFlowModal', {
      controller: CancelFlowModalComponent,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/client/modals/cancel-flow-modal/cancel-flow-modal.component.html'
    });

  CancelFlowModalComponent.$inject = ['subscriptionService', 'sharedService', '$window', '$interval', 'notificationService'];

  function CancelFlowModalComponent(subscriptionService, sharedService, $window, $interval, notificationService) {
    const me = this;

    me.$onInit = onInit;
    me.$onDestroy = onDestroy;
    me.isFormCompleted = isFormCompleted;
    me.onSubscriptionDone = onSubscriptionDone;
    me.doNotCancelAndGoBack = doNotCancelAndGoBack;
    me.continueCancelationOrDowngrade = continueCancelationOrDowngrade;
    me.isCloseActionDisabled = isCloseActionDisabled;
    me.selectActionOption = selectActionOption;
    me.isOptionSelected = isOptionSelected;
    me.isAnyOptionSelected = isAnyOptionSelected;
    me.cancel = cancel;
    me.actionOptions = {
      cancel: 1,
      downgrade: 2,
      upgrade: 3
    };

    me.user = sharedService.getUser();

    let typeFormCompleted = false;
    let typeFormCompletedCheckInterval;
    const typeFormMessageEventHandler = event => completeForm(event);

    function onInit() {
      $window.addEventListener('message', typeFormMessageEventHandler, false);
      typeFormCompletedCheckInterval = $interval(() => isFormCompleted(), 100);
      setInitialState();
    }

    function onDestroy() {
      $window.removeEventListener('message', typeFormMessageEventHandler, false);
    }

    function setInitialState() {
      me.selectedActionOption = null;
      me.cancelOrDowngradeMessageShown = false;
      me.canDowngradePlan = me.user.plan.downgradeOptions.length > 0;
      me.canUpgradePlan = me.user.plan.upgradeOptions.length > 0;
      me.remainingCreditMessage = buildRemainingCreditMessage();
    }

    function buildRemainingCreditMessage() {
      const sortedCreditProperties = ['years', 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'];
      const remainingCredit = sharedService.getCredit();
      const hasRemainingCredit = Object.keys(remainingCredit).length && sortedCreditProperties.some(p => remainingCredit[p] > 0);
      if (hasRemainingCredit) {
        const creditMessage = sortedCreditProperties.reduce((msg, p) => {
          if (remainingCredit[p] > 0) {
            const creditMsgChunck = `${remainingCredit[p]} ${p}`;
            msg += msg.length ? `, ${creditMsgChunck}` : creditMsgChunck;
          }
          return msg;
        }, '');
        return creditMessage + ' of credit';
      }

      return 'any remaining credit';
    }

    function completeForm(event) {
      if (event.data.type === 'embed-auto-close-popup') {
        typeFormCompleted = true;
        $interval.cancel(typeFormCompletedCheckInterval);
        subscriptionService.cancelUserPlan()
          .then(() => {
            typeformCleanup();
            me.close();
          }).catch(() => {
            notificationService.error(`Sorry, we failed to cancel your plan, please try again!`);
            cancel()
          });
      }
    }

    function isFormCompleted() {
      return typeFormCompleted;
    }

    function selectActionOption(selectedActionOption) {
      me.selectedActionOption = selectedActionOption;
    }

    function isOptionSelected(option) {
      return me.selectedActionOption === option;
    }

    function isAnyOptionSelected(options) {
      if (angular.isArray(options)) {
        return options.some(isOptionSelected)
      }

      return false;
    }

    function continueCancelationOrDowngrade() {
      me.cancelOrDowngradeMessageShown = true;
    }

    function doNotCancelAndGoBack() {
      setInitialState();
    }

    function isCloseActionDisabled() {
      return me.selectActionOption === me.actionOptions.cancel && isFormCompleted();
    }

    function typeformCleanup() {
      // To make typeform to re-render if modal is closed and opened again
      const script = document.querySelector('#typef_orm');
      if (script) {
        script.parentNode.removeChild(script);
        delete $window.typeformEmbedIsloaded;
        delete $window.typeformEmbed;
      }
    }

    function onSubscriptionDone(result) {
      notificationService.success(`Yaay! You subscribed to the ${result.planName} plan. We’re happy we will be seeing you around more often 🚀.`, true);
      me.close();
    }

    function cancel() {
      typeformCleanup();
      $interval.cancel(typeFormCompletedCheckInterval);
      me.dismiss();
    }

  }
})();
