(function () {
  'use strict';

  angular
    .module('ss.client.filters')
    .filter('secondsToTime', secondsToTime);

  function secondsToTime() {
    function padTime(time) {
      return time < 10 ? '0' + time : time;
    }

    return _seconds => {
      if (typeof _seconds !== 'number' || _seconds < 0) {
        return '00:00:00';
      }
      const hours = Math.floor(_seconds / 3600);
      const minutes = Math.floor((_seconds % 3600) / 60);
      const seconds = Math.floor(_seconds % 60);
      return hours > 0 ? hours + ':' + padTime(minutes) + ':' + padTime(seconds) : padTime(minutes) + ':' + padTime(seconds);
    };

  }
})();
