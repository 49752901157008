angular.module("ngConstants", [])

.constant("host", "https://simonsays.ai/")

.constant("ogUrl", "https://app.simonsays.ai/")

.constant("LANDING_PAGE_URL", "https://simonsays.ai")

.constant("PUBLIC_SITE_URL", "https://public.simonsays.ai")

.constant("AUTH0_CONFIG", {
	"clientID": "dS6Xi5tLNHwpXFGl1IdrO8D2j8dc6Lvu",
	"domain": "auth.simonsays.ai",
	"responseType": "token id_token",
	"redirectUri": "https://app.simonsays.ai/callback",
	"scope": "openid profile email read:messages",
	"audience": "https://simonsays.ai",
	"leeway": 180
})

.constant("APP_CONFIG", {
	"ROUTE_PREFIX": "/app",
	"ROUTE_ADMIN_PREFIX": "/admin",
	"LOGOUT_REDIRECT_TO": "https://app.simonsays.ai"
})

;