(function () {
  'use strict';

  angular
    .module('ss.client.components')
    .component('ssFooter', {
      controller: SSFooterComponent,
      bindings: {},
      templateUrl: 'app/client/components/ss-footer/ss-footer.component.html'
    });

  SSFooterComponent.$inject = ['sharedService'];

  function SSFooterComponent(sharedService) {
    const me = this;

    me.$onInit = onInit;
    me.footerDesktopOptions = [];
    me.user = sharedService.getUser();

    function onInit() {
      me.footerDesktopOptions = [{
        href: 'https://www.simonsays.ai/',
        icon: 'window-maximize',
        text: 'Web'
      }, {
        href: 'https://apps.apple.com/app/simon-says-transcription/id1441555493',
        icon: 'laptop',
        text: 'Mac App'
      }, {
        href: 'https://exchange.adobe.com/creativecloud.details.105339.simon-says-ai.html',
        icon: 'file-video',
        text: 'Adobe Ext'
      }, {
        href: 'https://apps.apple.com/app/simon-says-transcription/id1441555493',
        icon: 'film',
        text: 'FCPX Ext'
      }, {
        href: 'https://www.simonsays.ai/blackmagic-davinci-resolve-extension',
        icon: 'video',
        text: 'DaVinci Resolve Ext'
      }, {
        href: 'https://apps.apple.com/app/simon-says-transcription/id1434952638',
        icon: 'mobile-alt',
        text: 'iPhone'
      }, {
        href: 'https://www.simonsays.ai/on-premise-ai-transcription',
        icon: 'desktop',
        text: 'Mac/PC on-premises'
      }];
    }

  }
})();
